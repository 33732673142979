
import {computed, reactive, toRefs } from 'vue'
import { 
    isValid, 
    drawBillManual,
    redeemList,
    redeemDetail,
    redeemManual,
    raisePriceManual,
    redeemByCode
    } from '../../utils/api'
import { showMessage, geneIndex, isEmpty, formatTime,} from '@/utils/util'
import QrCodeVue from 'qrcode.vue'
export default {
    name: '',
    components: {QrCodeVue},
    setup() {
         const state= reactive({
             title: '订单详情',
             confirmLoading: false,
             showRedeem: false,
             showDialog: false,
             showSelection: true,
             orderId: '',
             ticketSn: '',
             columns: [
                 {title: '序号', width: 70, dataIndex: 'index', key: 'index'},
                 {title: '订单ID', ellipsis: true, dataIndex: 'orderId', key: 'orderId'},
                 {title: '票序列号', ellipsis: true, dataIndex: 'ticketSn', key: 'ticketSn'},
                 {title: '省', dataIndex: 'province', key: 'province'},
                 {title: '票种', dataIndex: 'ticketTypeText', key: 'ticketTypeText'},
                 {title: '奖金（元）', dataIndex: 'amount', key: 'amount'},
                 {title: '订单状态', dataIndex: 'statusText', key: 'statusText'}, 
                 {title: '兑奖结果', dataIndex: 'rewardStatusText', key: 'rewardStatusText'},
                 {title: '奖金状态', dataIndex: 'isPayText', key: 'isPayText'},
                 {title: '订单时间', dataIndex: 'createTime', key: 'createTime'},
                 {title: '更新时间', dataIndex: 'updateTime', key: 'updateTime'},
                 {title: '操作',  dataIndex: 'manage', key: 'name', width: 100, fixed: 'right',  slots: {customRender: 'actions'}},
             ],
             formItems: [
                 {type: 'input', label: '订单ID', value: undefined},
                 {type: 'input', label: '彩民ID', value: undefined},
                 {type: 'input', label: '省', value: undefined},
                 {type: 'selector', label: '票种', key: 'value', value: undefined, options: [{label: '体彩', value: 'TC'}, {label: '福彩', value: 'FC'}]},
                 {type: 'selector', label: '订单状态', key: 'value', options: [{label: '待处理', value: 0}, {label: '兑奖中', value: 5},{label: '兑奖失败', value: 9},{label: '兑奖成功', value: 10},]},
                 {type: 'selector', label: '奖金状态', key: 'value', options: [{label: '已支付', value: 1}, {label: '未支付', value: 0}]},
                 {type: 'button', label: '搜索', eventName: 'search'},
                 {type: 'button', label: '手动兑奖', eventName: 'redeemManualNoRecard'},
             ],
             redeemInfo: [
                {label: '中奖金额', typeValue: 4, value: '', type: 'input', name: 'amount',},
                {label: '中奖状态', value: '', type: 'selector', key: 'value', name: 'rewardStatus', options: [{labelName: '中奖', value: 99},{labelName: '此票未中奖', value: 2},{labelName: '重复兑奖', value: 1},{labelName: '跨区兑奖', value: 3},{labelName: '兑奖异常', value: 4},]},
                {label: '站点列表', typeValue: 4, value: '', type: 'selector', key: 'value', name: '', options: [{labelName: '5001901104', value: '5001901104'}]},
                {label: '备注', value: '', type: 'input', name: 'reward_msg',},
            ],
            manualRedeemInfo: [
                {label: '票号', value: '', type: 'input', name: ''},
                {label: '兑奖码', value: '', type: 'input', name: ''}
            ],

             buttons: [
                //  {text: '出票', eventName: 'drawBill'},
                 {text: '详情', eventName: 'detail'},
                 {text: '兑奖', eventName: 'redeem'},
                 {text: '加奖', eventName: 'raisePrize'},
             ],
             popForms: [
                 {label: '订单ID', value: '', type: 'input', name: 'orderId'},
                 {label: '彩民ID', value: '', type: 'input', name: 'consumerId'},
                 {label: '兑奖票的序列号', value: '', type: 'input', name: 'ticketSn'},
                 {label: '兑奖票所属省份', value: '', type: 'input', name: 'province'},
                 {label: '兑奖票类型', value: '', type: 'input', name: 'ticketTypeText'},
                 {label: '奖金（元）', value: '', type: 'input', name: 'amount'},
                 {label: '订单状态', value: '', type: 'input', name: 'statusText'},
                 {label: '中心兑奖状态', value: '', type: 'input', name: 'rewardStatusText'},
                 {label: '中心兑奖返回消息', value: '', type: 'input', name: 'rewardMsg'},
                 {label: '兑奖执行的设备号', value: '', type: 'input', name: 'rewardRunMachineNo'},
                 {label: '兑奖入账的设备号', value: '', type: 'input', name: 'rewardActualMachineNo'},
                 {label: '兑奖反馈时间', value: '', type: 'input', name: '兑奖反馈时间'},
                 {label: '兑奖入账时间', value: '', type: 'input', name: 'rewardActualTime'},
                 {label: '兑奖次数', value: '', type: 'input', name: '兑奖次数'},
                 {label: '奖金是否已经支付给用户', value: '', type: 'input', name: 'isPayText'},
                 {label: '给用户支付奖金时间', value: '', type: 'input', name: 'payTime'},
                 {label: '给用户支付奖金的方式', value: '', type: 'input', name: 'payType'},
                 {label: '第三方付款订单ID', value: '', type: 'input', name: 'payPartnerOrderId'},
                 {label: '创建时间', value: '', type: 'input', name: 'createTime'},
                //  {label: '', value: '', type: 'input', name: ''},
                ],
            tempForms: [{label: '设备ID', value: '', type: 'input', name: 'status'},],
             forms: {timeout: ''},
             tableList: [],
             currentPage: 1,
             pageSize: 10,
             total: 0,
             loading: false,
             status: 0,
             popFormsType: 0, // 0:详情 2:信息修改 3: 兑奖 4: 加奖 5:无兑奖记录手动兑奖
        })
        const readOnly = computed(() => {
            return state.popFormsType == 0
        })

        const showRedeemInfo = computed(() => {
            return state.popFormsType == 5 ? state.manualRedeemInfo 
                : state.popFormsType == 4 ? state.redeemInfo.filter(ele => ele.typeValue == 4) : state.redeemInfo
        })

        // 退票状态
        function refundStatus(status: any) {
            // 文字对应数字
            const textMap = new Map()
            // 数字对应文字
            const numMap = new Map()
            const arr = [
                ['初始', '0'],
                ['退款中', '1'],
                ['退款成功', '10'],
                ['退款失败', '40'],
                ['取消', '99'],
            ]
            arr.forEach(ele => {
                textMap.set(ele[0], ele[1])
                numMap.set(ele[1], ele[0])
            })

            if(textMap.has(status)) {
                return textMap.get(status)
            } else {
                return numMap.get(status)
            }
        }

         // 订单状态Map
        function billStatus(status: any) {
            // 文字对应数字
            const textMap = new Map()
            // 数字对应文字
            const numMap = new Map()
            const arr = [
                ['待处理', 0],
                ['兑奖中', 5],
                ['兑奖失败', 9],
                ['兑奖成功', 10],
            ]
            arr.forEach(ele => {
                textMap.set(ele[0], ele[1])
                numMap.set(ele[1], ele[0])
            })

            if(textMap.has(status)) {
                return textMap.get(status)
            } else {
                return numMap.get(status)
            }
        }

        // 支付状态
        function payStatus(status: any) {
            const textMap = new Map()
            const letterMap = new Map()

            const arr = [
                ['初始', '0'],
                ['等待结果', '1'],
                ['已付款', '10'],
                ['付款失败', '40'],
                ['取消', '99'],
            ]
            arr.forEach(ele => {
                textMap.set(ele[1], ele[0])
                letterMap.set(ele[0], ele[1])
            })
            if (textMap.has(status)) {
                return textMap.get(status)
            } else {
                return letterMap.get(status)
            }
        }
        // 无兑奖记录手动兑奖
        function redeemManualNoRecard() {
            state.popFormsType = 5
            state.tempForms = showRedeemInfo.value
            state.showDialog = true
        }
        function initTableText(data: any) {
            data.forEach((ele: any) => {
                ele.statusText = billStatus(ele.status)
                ele.payStatusText = payStatus(ele.payStatus)
                ele.refundStatusText = refundStatus(ele.refundStatus)
                ele.fundStatus = ele.isExistRefund == 0 ? '否' : '是'
                ele.createTime = formatTime(ele.createTime)

                ele.updateTime = formatTime(ele.updateTime) || '暂无'

                ele.isPayText = ele.isPay ? '已支付' : '未支付'
                ele.ticketTypeText = ele.ticketType == 'TC' ? '体彩' : '福彩'
                const rs = ele.rewardStatus 
                ele.rewardStatusText = rs == 0 ? '等待结果'
                                  : rs == 1 ? '该票已经兑过奖，无法重复兑奖'
                                  : rs == 2 ? '未中奖'
                                  : rs == 3 ? '非本省票，无法兑奖'
                                  : rs == 4 ? '服务器异常，请重试'
                                  : rs == 5 ? '兑奖票号异常，无法兑奖'
                                  : rs == 98 ? '大奖票，系统无法兑奖，请去中心兑奖'
                                  : rs == 99 ? '中奖' : '暂无'
            })
            return data
        }
        // 获取设备列表
        async function  loadData(pageNum = 1) {
            try {
                state.loading = true
                state.currentPage = pageNum || state.currentPage
                const forms = state.formItems
                const params = {
                    orderId: forms[0].value,
                    consumerId: forms[1].value,
                    province: forms[2].value,
                    ticketType: forms[3].value,
                    status: forms[4].value,
                    isPay: forms[5].value,
                    pageNum: state.currentPage,
                    pageSize: state.pageSize,
                }
                const result = await redeemList(params)
                state.loading = false
                if(isValid(result)) {
                    state.total = result.data.total
                    state.tableList = initTableText(result.data.records)
                    state.tableList = geneIndex(state.tableList, state.pageSize, state.currentPage)
                }
                if (isValid(result)) {
                    console.log(result)
                }
            } catch(e) {
                state.loading = false
                console.error(e)
            }
        }
        async function manualRedeem() {
            try {
                const items = state.redeemInfo
                if (isEmpty(items)) {
                    return
                }
                const params = {
                    orderId: state.orderId,
                    code: items[1].value,
                    msg: items[3].value,
                    rewardRunMachineNo: items[2].value,
                    amount: items[0].value,
                }
                const result = await redeemManual(params)
                if (result.code == 0) {
                    showMessage('填写成功')
                    state.showDialog = false
                    loadData(state.currentPage)
                    state.redeemInfo.forEach(ele => ele.value = '')
                }
            } catch(e) {
                console.error(e)
            }
            
        }

        async function raisePriceApi() {
            try {
                const items = state.tempForms
                if (isEmpty(items)) {
                    return
                }
                const params = {
                    orderId: state.orderId,
                    rewardRunMachineNo: items[1].value,
                    amount: items[0].value,
                }
                const result = await raisePriceManual(params)
                if (result.code == 0) {
                    showMessage('加奖成功')
                    state.showDialog = false
                    loadData(state.currentPage)
                    state.redeemInfo.forEach(ele => ele.value = '')
                }
            } catch(e) {
                console.error(e)
            }
        }

        async function redeemByCodeEvent() {
            try {
                const items = state.tempForms
                if (isEmpty(items)) {
                    return
                }
                const params = {
                    lotteryTicketNumber: items[0].value,
                    lotteryRedemptionNumber: items[1].value
                }
                const result = await redeemByCode(params)
                if (result.code == 0) {
                    showMessage('兑奖成功')
                    state.showDialog = false
                    loadData(state.currentPage)
                }
            } catch(e) {
                console.error(e)
            }
        }

        async function changePop() {
            if (state.popFormsType == 0) {
                state.showDialog = false
            } else if (state.popFormsType == 3) {
                await manualRedeem()
            } else if (state.popFormsType == 4) {
                await raisePriceApi()
            } else if (state.popFormsType == 5) {
                 await redeemByCodeEvent()
            }
        }
        // 初始化显示详情
        function initDetailData(data: any) {
            const forms = state.popForms

            const tt = data.ticketType
            console.log(tt)
            data.ticketTypeText = tt == 'TC' ? '体彩' : '福彩'

            const s = data.status 
            data.statusText = billStatus(data.status)

            const pt = data.payType
            data.payTypeText = pt == 'wallet' ? '转到钱包' : '转到用户微信'

            const rs = data.rewardStatus 
            data.rewardStatusText = rs == 0 ? '等待结果'
                                  : rs == 1 ? '该票已经兑过奖，无法重复兑奖'
                                  : rs == 2 ? '未中奖'
                                  : rs == 3 ? '非本省票，无法兑奖'
                                  : rs == 4 ? '服务器异常，请重试'
                                  : rs == 5 ? '兑奖票号异常，无法兑奖'
                                  : rs == 98 ? '大奖票，系统无法兑奖，请去中心兑奖'
                                  : rs == 99 ? '中奖' : '暂无'
            forms.forEach(ele => {
                ele.value = data[ele.name]
            })
        }
        // 兑奖
        function redeem(info: any) {
            state.orderId = info.orderId
            state.ticketSn = info.ticketSn
            state.popFormsType = 3
            state.redeemInfo.forEach(ele => {
                ele.value = info[ele.name] || ''
            })
            state.tempForms[0].name = 'amount'
            state.tempForms[0].label = '中奖金额'
            state.tempForms = state.redeemInfo
            state.showDialog = true

        }
        // 加奖
        function raisePrize(info: any) {
            state.orderId = info.orderId
            state.ticketSn = info.ticketSn
            state.popFormsType = 4
            state.redeemInfo.forEach(ele => {
                ele.value = info[ele.name] || ''
            })
            state.tempForms = state.redeemInfo.filter(ele => ele.typeValue == 4)
            state.tempForms[0].name = ''
            state.tempForms[0].label = '加奖金额'
            state.showDialog = true
        }
        // 查看设备详情
        async function detail(event: any) {
            try {
                const result = await redeemDetail(event.orderId)
                if (isValid(result)) {
                    state.popFormsType = 0
                    state.tempForms = state.popForms
                    initDetailData(event)
                    state.showDialog = true
                }
            } catch(e) {
                console.error(e)
            }
        }
        // 手动出票
        async function drawBill(event: any) {
            try {
                const result = await drawBillManual(event.id)
                if (result.code == 0) {
                    showMessage('出票成功')
                    loadData()
                }
            } catch(e) {
                console.error(e)
            }
        }
        loadData()
        return {
            ...toRefs(state),
            detail,
            loadData,
            drawBill,
            redeem,
            readOnly,
            changePop,
            showRedeemInfo,
            raisePrize,
            redeemManualNoRecard
        }
    },
}
